import { render, staticRenderFns } from "./crudServicesCustomers.vue?vue&type=template&id=23542f7a&scoped=true&"
import script from "./crudServicesCustomers.vue?vue&type=script&lang=js&"
export * from "./crudServicesCustomers.vue?vue&type=script&lang=js&"
import style0 from "./crudServicesCustomers.vue?vue&type=style&index=0&id=23542f7a&scoped=true&lang=css&"
import style1 from "./crudServicesCustomers.vue?vue&type=style&index=1&id=23542f7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23542f7a",
  null
  
)

export default component.exports